.footer {
  display: flex;
  flex-direction: column;

  &-feedback {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 54px 120px;
    margin-top: 130px;
    background: #f2f2f2;

    &-call {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      & h3 {
        font-size: 2em;

        @media (max-width: 1600px) {
          font-size: 1.8em;
        }
      }

      &-social {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 34px;
        margin-top: 37px;
        margin-bottom: 22px;

        @media (max-width: 1600px) {
          margin-top: 24px;
          margin-bottom: 15px;

          & svg {
            width: 44px;
          }
        }
      }

      & a {
        font-size: 3em;
        color: #000;
        text-decoration: none;

        @media (max-width: 1600px) {
          font-size: 2.5em;
        }
      }
    }

    &-form {
      width: 100%;
      height: 600px;
      background: linear-gradient(to right, #c85bd5 0%, #00d7fa 100%);
      border-radius: 77px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 64px 0;

      @media (max-width: 1600px) {
        height: 480px;
      }

      &-title {
        font-size: 46px;
        font-weight: 600;
        text-align: center;
        padding: 0px 75px;
        color: #fff;
        margin-bottom: 30px;

        @media (max-width: 1600px) {
          font-size: 28px;
          padding: 0px 50px;
          margin-bottom: 15px;
        }
      }

      &-box {
        width: 100%;
        padding: 0 108px;

        &-input {
          display: flex;
          flex-direction: column;
          gap: 5px;

          & label {
            font-size: 20px;
            font-weight: 500;
            color: #fff;

            @media (max-width: 1600px) {
              font-size: 16px;
            }
          }

          & input {
            height: 50px;
            border-radius: 5px;
            outline: 0;
            box-shadow: none;
            border: 0;
            width: 100%;
            padding-left: 28px;

            &::placeholder {
              color: #d9d9d9;
            }
          }
        }
      }

      &-btn {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 36px;
        font-size: 18px;
        font-weight: 500;
        padding: 16px 85px;
        cursor: pointer;

        @media (max-width: 1600px) {
          font-size: 16px;
          padding: 14px 65px;
        }
      }
    }
  }

  &-menu {
    width: 100%;
    height: 240px;
    background: #1e275e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: 1600px) {
      height: 180px;

      & img {
        width: 35%;
      }
    }

    &-up {
      width: 60px;
      height: 60px;
      background: #fff;
      border-radius: 15px;
      position: absolute;
      top: 50%;
      right: 0%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 1600px) {
        width: 45px;
        height: 45px;
        border-radius: 13px;
        right: 2%;
      }

      & svg {
        width: 20px;
        height: 10px;

        @media (max-width: 1600px) {
          width: 15px;
          height: 7px;
        }
      }
    }
  }
}

.margin-box {
  margin-top: 50px;
  margin-bottom: 60px;

  @media (max-width: 1600px) {
    margin-top: 30px;
    margin-bottom: 35px;
  }
}
