@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-MediumItalic.eot');
  src: local('Google Sans Medium Italic'), local('GoogleSans-MediumItalic'),
    url('GoogleSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-MediumItalic.woff2') format('woff2'),
    url('GoogleSans-MediumItalic.woff') format('woff'),
    url('GoogleSans-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-Bold.eot');
  src: local('Google Sans Bold'), local('GoogleSans-Bold'),
    url('GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-Bold.woff2') format('woff2'),
    url('GoogleSans-Bold.woff') format('woff'),
    url('GoogleSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-Italic.eot');
  src: local('Google Sans Italic'), local('GoogleSans-Italic'),
    url('GoogleSans-Italic.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-Italic.woff2') format('woff2'),
    url('GoogleSans-Italic.woff') format('woff'),
    url('GoogleSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-Medium.eot');
  src: local('Google Sans Medium'), local('GoogleSans-Medium'),
    url('GoogleSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-Medium.woff2') format('woff2'),
    url('GoogleSans-Medium.woff') format('woff'),
    url('GoogleSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-Regular.eot');
  src: local('Google Sans Regular'), local('GoogleSans-Regular'),
    url('GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-Regular.woff2') format('woff2'),
    url('GoogleSans-Regular.woff') format('woff'),
    url('GoogleSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('GoogleSans-BoldItalic.eot');
  src: local('Google Sans Bold Italic'), local('GoogleSans-BoldItalic'),
    url('GoogleSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('GoogleSans-BoldItalic.woff2') format('woff2'),
    url('GoogleSans-BoldItalic.woff') format('woff'),
    url('GoogleSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Unicorn';
  src: url('Unicorn Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
